import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance'; // Your axios setup

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function validateToken() {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.post('/validate', { token });
        setUser(response.data.user);
      } catch (error) {
        setToken(null);
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    }

    validateToken();
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, setUser, token, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
