import React, { createContext, useState, useEffect } from 'react';
import ManagerService from '../services/ManagerService'; // Import ManagerService

// Create a context for Manager data
export const ManagerContext = createContext();

export const ManagerProvider = ({ children }) => {
  const [manager, setManager] = useState(null); // Manager details including name and notifications
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch manager information on component mount
  useEffect(() => {
    const fetchManagerData = async () => {
      try {
        setLoading(true);
        const data = await ManagerService.getManagerInfo(); // Fetch manager info

        // Assuming `manager` object contains `name` and `notifications`
        setManager(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch manager data:', error);
        setError('Failed to load manager information');
        setLoading(false);
      }
    };

    fetchManagerData();
  }, []);

  return (
    <ManagerContext.Provider value={{ manager, loading, error }}>
      {children}
    </ManagerContext.Provider>
  );
};