import axiosInstance from './axiosInstance'; // Import the axios instance from the same folder

const NotificationsService = {
  // Save the FCM token to the server
  saveToken: async (token) => {
    try {
      const response = await axiosInstance.post('/notifications/save-token', {
        token: token,
      });
      console.log('Token stored successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error storing token:', error);
      throw error; // Re-throw the error to handle it further up if necessary
    }
  },

  fetchQuickNotifications: async (userId) => {
    try {
      const response = await axiosInstance.get(`/notifications/quick/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching quick notifications for user ID ${userId}:`, error);
      throw error;
    }
  },

  // Fetch notifications based on filters (isRead, isArchived, type)
  fetchMyNotifications: async (filters = {}) => {
    try {
      const response = await axiosInstance.get(`/notifications/me`, {
        params: {
          isRead: filters.isRead,
          isArchived: filters.isArchived,
          type: filters.type,
          limit: filters.limit || 20,
          offset: filters.offset || 0,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching notifications for user ID ${userId}:`, error);
      throw error;
    }
  },



  // Mark a notification as read by notification ID
  markAsRead: async (notificationId) => {
    try {
      const response = await axiosInstance.post(`/notifications/${notificationId}/mark-as-read`);
      return response.data;
    } catch (error) {
      console.error(`Error marking notification ID ${notificationId} as read:`, error);
      throw error;
    }
  },

  // Archive a notification by notification ID
  archiveNotification: async (notificationId) => {
    try {
      const response = await axiosInstance.post(`/notifications/${notificationId}/archive`);
      return response.data;
    } catch (error) {
      console.error(`Error archiving notification ID ${notificationId}:`, error);
      throw error;
    }
  },

  // Fetch notification details by its ID
  getNotificationById: async (notificationId) => {
    try {
      const response = await axiosInstance.get(`/notifications/${notificationId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching notification ID ${notificationId}:`, error);
      throw error;
    }
  },

  // Delete a notification by its ID
  deleteNotification: async (notificationId) => {
    try {
      const response = await axiosInstance.delete(`/notifications/${notificationId}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting notification ID ${notificationId}:`, error);
      throw error;
    }
  },

  // Create a new notification
  createNotification: async ({ title, body, recipientIds, sendToAll }) => {
    try {
      const response = await axiosInstance.post('/notifications/create', {
        title,
        body,
        recipientIds,
        sendToAll,
      });
      console.log('Notification created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating notification:', error);
      throw error;
    }
  },

  // Send a notification to all users
  sendNotificationToAll: async (title, body) => {
    try {
      const response = await axiosInstance.post('/notifications/send-all', {
        title,
        body,
      });
      console.log('Notification sent to all users:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error sending notification to all users:', error);
      throw error;
    }
  },
};

export default NotificationsService;