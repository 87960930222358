import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance'; // Your axios setup

export function useAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(() => localStorage.getItem('token'));

  useEffect(() => {
    if (token) {
      axiosInstance.post('/validate', { token })
        .then(response => setUser(response.data.user))
        .catch(() => {
          setToken(null);
          localStorage.removeItem('token');
        });
    }
  }, [token]);

  return { user, token };
}
