// services/axiosInstance.js
import axios from 'axios';

// Base API instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Main API instance
const axiosMainInstance = axios.create({
  baseURL: process.env.REACT_APP_API_MAIN_URL,
});

// Interceptors for axiosInstance
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptors for axiosMainInstance
axiosMainInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Default export for axiosInstance
export default axiosInstance;

// Named export for axiosMainInstance
export { axiosMainInstance };
