import React, { Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './auth/AuthContext'; // Import AuthProvider
import AuthContextComponent from './auth/AuthContextComponent'; // Import AuthContextComponent

// Lazy loading components
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const MainLayout = React.lazy(() => import('./layouts/MainLayout'));
const UsersPage = React.lazy(() => import('./pages/UsersPage'));
const ReportsPage = React.lazy(() => import('./pages/ReportsPage'));
const ProjectsPage = React.lazy(() => import('./pages/ProjectsPage'));
const ProjectDetailsPage = React.lazy(() => import('./pages/ProjectDetailsPage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const FinancePage = React.lazy(() => import('./pages/FinancePage'));
const ServicesPage = React.lazy(() => import('./pages/ServicesPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const RolesPage = React.lazy(() => import('./pages/RolesPage'));
const RolesItemPage = React.lazy(() => import('./pages/RolesItemPage'));
const TemplatesPage = React.lazy(() => import('./pages/TemplatesPage'));
const UserDetailsPage = React.lazy(() => import('./pages/UserDetailsPage'));
const TemplateItemPage = React.lazy(() => import('./pages/TemplateItemPage'));
const TasksManagementPage = React.lazy(() => import('./pages/TasksManagementPage'));
const DocumentEditorPage = React.lazy(() => import('./pages/DocumentEditorPage'));
const PrivateChatPage = React.lazy(() => import('./pages/PrivateChatPage'));
const ArchivePage = React.lazy(() => import('./pages/ArchivePage'));
const ArchivedProjectsPage = React.lazy(() => import('./pages/ArchivedProjectsPage'));
const ArchivedReportsPage = React.lazy(() => import('./pages/ArchivedReportsPage'));
const ArchivedChatPage = React.lazy(() => import('./pages/ArchivedChatPage'));
const ArchivedTasksPage = React.lazy(() => import('./pages/ArchivedTasksPage'));
const ArchivedDocumentsPage = React.lazy(() => import('./pages/ArchivedDocumentsPage'));
const ManageTaskPage = React.lazy(() => import('./pages/ManageTaskPage'));
const NotificationsPage = React.lazy(() => import('./pages/NotificationsPage'));
const EmailPage = React.lazy(() => import('./pages/EmailPage'));
const ChatPage = React.lazy(() => import('./pages/ChatPage'));

const App = () => {
  // Memoizing the theme creation to avoid unnecessary recalculations
  const theme = useMemo(
    () =>
      createTheme({
        direction: 'rtl',
        typography: {
          fontFamily: '"Zain", "Roboto", "Helvetica", "Arial", sans-serif',
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Suspense fallback={<div>[جاري التحميل]...</div>}>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/document-editor" element={<DocumentEditorPage />} />
              <Route path="/" element={<LoginPage />} />

              {/* Protected Routes */}
              <Route
                element={
                  <AuthContextComponent redirectTo="/login">
                    <MainLayout />
                  </AuthContextComponent>
                }
              >
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/chat" element={<ChatPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/:id" element={<ProjectDetailsPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/finance" element={<FinancePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/archive" element={<ArchivePage />} />
                <Route path="/task/:taskId" element={<ManageTaskPage />} />
                <Route path="/tasks/manage" element={<ManageTaskPage />} />
                <Route path="/task-management" element={<TasksManagementPage />} />
                <Route path="/roles" element={<RolesPage />} />
                <Route path="/roles/:roleId" element={<RolesItemPage />} />
                <Route path="/templates" element={<TemplatesPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users/:id" element={<UserDetailsPage />} />
                <Route path="/templates/:id" element={<TemplateItemPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/manager-chat/:userId" element={<PrivateChatPage />} />
                <Route path="/archive/documents" element={<ArchivedDocumentsPage />} />
                <Route path="/archive/projects" element={<ArchivedProjectsPage />} />
                <Route path="/archive/tasks" element={<ArchivedTasksPage />} />
                <Route path="/archive/chat" element={<ArchivedChatPage />} />
                <Route path="/archive/reports" element={<ArchivedReportsPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/emails" element={<EmailPage />} />
              </Route>
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
