// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modals/modalSlice';

const store = configureStore({
  reducer: {
    modal: modalReducer, // Add the modal reducer
  },
});

export default store;
