import axiosInstance from './axiosInstance'; // Import the axios instance

const ManagerService = {
  // 1. Login method
  login: async (username, password) => {
    try {
      const response = await axiosInstance.post('/login', {
        username,
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  },

  // 2. Get manager info using `/me` endpoint
  getManagerInfo: async () => {
    try {
      const response = await axiosInstance.get('/me');
      return response.data;
    } catch (error) {
      console.error('Error fetching manager info:', error);
      throw error;
    }
  },

  // 3. Get all roles (with optional users)
  getRoles: async (includeUsers = false) => {
    try {
      const url = includeUsers ? '/roles?users' : '/roles';
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching roles from /roles:', error);
      throw error;
    }
  },

  // 4. Fire an employee
  fireEmployee: async (userId) => {
    try {
      const response = await axiosInstance.post(`/users/fire/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error firing employee:', error);
      throw error;
    }
  },
  
};

export default ManagerService;