// EmployeeContext.js
import React, { createContext, useState, useEffect } from 'react';
import ProjectService from '../services/ProjectService';

export const EmployeeContext = createContext();

export const EmployeeProvider = ({ children }) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const data = await ProjectService.getAvailableEmployees(); // Fetch the employee list from an API
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  return (
    <EmployeeContext.Provider value={{ employees, loading }}>
      {children}
    </EmployeeContext.Provider>
  );
};
