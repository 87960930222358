import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ProjectService = {
  // 1. Get all projects
  getAllProjects: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects`);
      return response.data;
    } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
    }
  },

  // 2. Get project by ID
  getProjectById: async (projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/project/${projectId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching project:', error);
      throw error;
    }
  },

  // -------- Project State Management --------
  // 3. Update the Status of the Project

  updateProjectStatus: async (projectId, newState) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/status`, { newState });
      return response.data;
    } catch (error) {
      console.error('Error updating project state:', error);
      throw error;
    }
  },

  // 3. Pause a project (separate route for pausing)
  pauseProject: async (projectId) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/pause`);
      return response.data;
    } catch (error) {
      console.error('Error pausing project:', error);
      throw error;
    }
  },

  // 4. Resume a project (separate route for resuming)
  resumeProject: async (projectId) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/resume`);
      return response.data;
    } catch (error) {
      console.error('Error resuming project:', error);
      throw error;
    }
  },

  // 5. Complete a project
  completeProject: async (projectId) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/state`, { newState: 'completed' });
      return response.data;
    } catch (error) {
      console.error('Error completing project:', error);
      throw error;
    }
  },

  // 6. Add a new project
  addProject: async (projectData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects`, projectData);
      return response.data;
    } catch (error) {
      console.error('Error adding project:', error);
      throw error;
    }
  },

  // -------- Stage & Phase Transitions --------

  // 7. Transition to the next stage (no need for stageId)
  transitionNextStage: async (projectId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/stages/next`);
      return response.data;
    } catch (error) {
      console.error('Error transitioning to next stage:', error);
      throw error;
    }
  },

  // 8. Transition to the next phase (no need for currentPhaseId)
  transitionNextPhase: async (projectId, stageId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/stages/${stageId}/phases/next`);
      return response.data;
    } catch (error) {
      console.error('Error transitioning to next phase:', error);
      throw error;
    }
  },

  // -------- Team Management --------

  // 9. Assign team members to a project
  assignTeamMembers: async (projectId, teamMembers) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/team`, { teamMembers });
      return response.data;
    } catch (error) {
      console.error('Error assigning team members:', error);
      throw error;
    }
  },

  // 10. Get all team members of a project
  getTeamMembers: async (projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/team`);
      return response.data;
    } catch (error) {
      console.error('Error fetching team members:', error);
      throw error;
    }
  },

  // 11. Update team member role
  updateTeamMemberRole: async (projectId, userId, role) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/projects/${projectId}/team/${userId}`, { role });
      return response.data;
    } catch (error) {
      console.error('Error updating team member role:', error);
      throw error;
    }
  },

  // 12. Remove a team member from a project
  removeTeamMember: async (projectId, userId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/projects/${projectId}/team/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error removing team member:', error);
      throw error;
    }
  },

  // -------- Document Management --------

  // 13. Add a document to a project
  addDocument: async (projectId, documentData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/documents`, documentData);
      return response.data;
    } catch (error) {
      console.error('Error adding document:', error);
      throw error;
    }
  },

  // 14. Get all documents related to a project
  getDocuments: async (projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/documents`);
      return response.data;
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  },

  // 15. Update document's info
  updateDocument: async (projectId, documentId, documentData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/projects/${projectId}/documents/${documentId}`, documentData);
      return response.data;
    } catch (error) {
      console.error('Error updating document:', error);
      throw error;
    }
  },

  // 16. Delete a document from a project
  deleteDocument: async (projectId, documentId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/projects/${projectId}/documents/${documentId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  },

  // 17. Archive a document
  archiveDocument: async (projectId, documentId) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/documents/${documentId}/archive`);
      return response.data;
    } catch (error) {
      console.error('Error archiving document:', error);
      throw error;
    }
  },

  setPrivilegeForDocument: async(projectId, documentId, privilegeList) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/documents/${documentId}/privilege`, privilegeList);
      return response.data;
    } catch (error) {
      console.error('Error setting privilege for document:', error);
    }
  },
  // -------- Project Archives --------

  // 18. Archive a project
  archiveProject: async (projectId) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/projects/${projectId}/archive`);
      return response.data;
    } catch (error) {
      console.error('Error archiving project:', error);
      throw error;
    }
  },

  // 19. Get the stages for the project
  getProjectStages: async (projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/stages-phases/${projectId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching project stages:', error);
    }
  },

  // 20. Get the available employees for the project
  getAvailableEmployees: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/available`);
      return response.data;
    } catch (error) {
      console.error('Error fetching available employees:', error);
      throw error;
    }
  },

  // 21. Get project tasks
  getProjectTasks: async(projectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/tasks`);
      return response.data;
    } catch(error) {
      console.error('Failed to get the project tasks');
    }
  }
};

export default ProjectService;