import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Import i18n configuration

// Step 1: Import Provider from react-redux and store
import { Provider } from 'react-redux';
import store from './store'; // Import the Redux store you configured

// Import NotificationsProvider from NotificationsContext
import NotificationsProvider from './contexts/NotificationsContext';

// Import EmployeeProvider from EmployeeContext
import { EmployeeProvider } from './contexts/EmployeeContext';

// Import ManagerProvider from ManagerContext
import { ManagerProvider } from './contexts/ManagerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* Step 2: Wrap the App component with Provider and pass the store */}
    <Provider store={store}>
      {/* Step 3: Wrap the App with NotificationsProvider */}
      <NotificationsProvider>
        {/* Step 4: Wrap the App with EmployeeProvider */}
        <EmployeeProvider>
          {/* Step 5: Wrap the App with ManagerProvider */}
          <ManagerProvider>
            <App />
          </ManagerProvider>
        </EmployeeProvider>
      </NotificationsProvider>
    </Provider>
  </React.StrictMode>
);

// Measure performance
reportWebVitals();