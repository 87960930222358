import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const AuthContextComponent = ({ children, allowedFor, redirectTo }) => {
  const { user, token, loading } = useAuth();

  // Show a loading spinner while checking authentication
  if (loading) {
    return <div>Loading...</div>; // Or a styled loading spinner component
  }

  // If no token, redirect to login
  if (!token) {
    return <Navigate to="/login" />;
  }

  // If user is confirmed and trying to access a path meant for unconfirmed users (like /confirm-email), redirect
  if (user?.isConfirmed && allowedFor === 'unconfirmed') {
    return <Navigate to={redirectTo} />;
  }

  // If conditions pass, render the requested route
  return children;
};

export default AuthContextComponent;
